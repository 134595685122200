import React from 'react'
import {
    useTranslate,
    useDataProvider,
    GET_LIST
} from 'react-admin'
import { format, parseISO } from 'date-fns'
import { IconButton } from '@mui/material'
import DownloadIcon from '@material-ui/icons/GetApp'
import requests from '../../core/requests'
import { formatMobile, getExporter } from '../../tools/utils'

const SHEET_NAME = 'Orders'

const ExportToSystemAdmin = ({ filterValues, currentSort }) => {
    const restProvider = useDataProvider()
    const translate = useTranslate()

    const getColumns = (order) => {
        const orderColumns = [
            {
                header: translate('ra.report_pos_system_admin.columns.created_at'),
                key: 'createdAt'
            },
            {
                header: translate('ra.report_pos_system_admin.columns.org_name'),
                key: 'orgName'
            },
            {
                header: translate('ra.report_pos_system_admin.columns.system_name'),
                key: 'systemName'
            },
            {
                header: translate('ra.report_pos_system_admin.columns.order_number'),
                key: 'orderNumber'
            },
            {
                header: translate('ra.report_pos_system_admin.columns.total_sum'),
                key: 'totalSum'
            }
        ]
        for (const key in order.fields) {
            orderColumns.push({ header: key, key: key })
        }
        const itemColumns = [
            {
                header: translate('ra.report_pos_system_admin.columns.category'),
                key: 'category'
            },
            {
                header: translate('ra.report_pos_system_admin.columns.item'),
                key: 'item'
            },
            {
                header: translate('ra.report_pos_system_admin.columns.price'),
                key: 'price'
            },
            {
                header: translate('ra.report_pos_system_admin.columns.quantity'),
                key: 'quantity'
            },
            {
                header: translate('ra.report_pos_system_admin.columns.item_remarks'),
                key: 'itemRemarks'
            },
        ]
        return [...orderColumns, ...itemColumns]
    }

    const getOrderData = (order, orgName, systemName, index) => {
        const orderData = {
            createdAt: format(parseISO(order.createdAt), 'HH:mm dd/MM/yyyy'),
            orgName: orgName,
            systemName: systemName,
            orderNumber: order.order_number,
            totalSum: order.items.reduce((a, b) => {
                return b.price * b.quantity + a
            }, 0)
        }
        for (const key in order.fields) {
            orderData[key] = order.fields[key]
        }
        if (index > 0) {
            for (const key in orderData) {
                orderData[key] = ''
            }
        }
        return orderData
    }

    const getItemData = (item) => {
        return {
            category: item.category,
            item: item.name,
            price: item.price,
            quantity: item.quantity,
            itemRemarks: item.remarks
        }
    }

    const getRecords = (orders, orgName, systemName) => (
        orders.flatMap((order) => (
            order.items.map((item, index) => {
                return {
                    ...getOrderData(order, orgName, systemName, index),
                    ...getItemData(item)
                }
            })
        ))
    )

    const getFileName = (orgName, systemName) => (
        `${orgName}-${systemName}-${translate('ra.report_pos_system_admin.system_admin')}`
    )

    const getOrders = async () => {
        const res = await restProvider(GET_LIST, 'orders', { filter: filterValues, sort: currentSort, pagination: {} })
        return Object.values(res.data)
    }

    const getOrgName = async (orgId) => {
        const res = await requests.getOrganizations()
        const organizations = res.data
        return organizations.find(org => org.id === orgId)?.display_name
    }

    const getSystemName = async (orgId, systemId) => {
        const res = await requests.getSystems(orgId)
        const systems = res.data
        return systems.find(system => system.system_id === Number(systemId))?.system_name
    }

    const exportToXls = async () => {
        try {
            const orders = await getOrders()
            if (!orders.length) {
                return
            }
            const orgName = await getOrgName(orders[0].org_id)
            const systemName = await getSystemName(orders[0].org_id, orders[0].system_id)
            const columns = getColumns(orders[0])
            const records = getRecords(orders, orgName, systemName)
            getExporter(getFileName(orgName, systemName))(SHEET_NAME, columns, records)
        } catch (e) {
            console.log("error", e)
        }
    }

    return (
        <IconButton
            size='small'
            color='primary'
            onClick={exportToXls}>
            <DownloadIcon />
            {translate("ra.action.export_admin_system")}
        </IconButton>
    )
}
export default ExportToSystemAdmin